define("discourse/plugins/discourse-policy/discourse/controllers/policy-builder", ["exports", "@ember/utils", "I18n", "discourse/lib/text", "discourse/mixins/modal-functionality", "@ember/controller", "@ember/object", "discourse-common/lib/get-url", "discourse/lib/ajax"], function (_exports, _utils, _I18n, _text, _modalFunctionality, _controller, _object, _getUrl, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_obj = {
    toolbarEvent: null,
    post: null,
    isSaving: false,
    insertMode: true,
    onShow() {
      if (!this.policy) {
        this.set("policy", _object.default.create({
          reminder: "daily",
          version: 1
        }));
      }
    },
    onClose() {
      this.set("policy", null);
    },
    onChangeFormField(field, value) {
      this.policy?.set(field, value);
    },
    insertPolicy() {
      if (!this._validateForm(this.policy)) {
        return;
      }
      const markdownParams = this._buildParams(this.policy);
      this.toolbarEvent.addText(`\n\n[policy ${markdownParams.join(" ")}]\n${_I18n.default.t("discourse_policy.accept_policy_template")}\n[/policy]\n\n`);
      this.send("closeModal");
    },
    updatePolicy() {
      if (!this._validateForm(this.policy)) {
        return;
      }
      this.set("isSaving", true);
      const endpoint = (0, _getUrl.default)(`/posts/${this.post.id}`);
      const options = {
        type: "GET",
        cache: false
      };
      return (0, _ajax.ajax)(endpoint, options).then(result => {
        const raw = result.raw;
        const newRaw = this._replaceRaw(this.policy, raw);
        if (newRaw) {
          const props = {
            raw: newRaw,
            edit_reason: _I18n.default.t("discourse_policy.edit_reason")
          };
          return (0, _text.cook)(raw).then(cooked => {
            props.cooked = cooked.string;
            this.post.save(props);
          });
        }
      }).finally(() => {
        this.set("isSaving", false);
        this.send("closeModal");
      });
    },
    _buildParams(policy) {
      const markdownParams = [];
      Object.keys(policy).forEach(key => {
        const value = policy[key];
        if (value && (0, _utils.isPresent)(value)) {
          markdownParams.push(`${key}="${value}"`);
        }
      });
      return markdownParams;
    },
    _replaceRaw(policy, raw) {
      const policyRegex = new RegExp(`\\[policy\\s(.*?)\\]`, "m");
      const policyMatches = raw.match(policyRegex);
      if (policyMatches && policyMatches[1]) {
        const markdownParams = this._buildParams(policy);
        return raw.replace(policyRegex, `[policy ${markdownParams.join(" ")}]`);
      }
      return false;
    },
    _validateForm(policy) {
      if (!policy.groups || !(0, _utils.isPresent)(policy.groups)) {
        this.flash(_I18n.default.t("discourse_policy.builder.errors.group"), "error");
        return false;
      }
      if (!policy.version || !(0, _utils.isPresent)(policy.version)) {
        this.flash(_I18n.default.t("discourse_policy.builder.errors.version"), "error");
        return false;
      }
      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "onChangeFormField", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onChangeFormField"), _obj), _applyDecoratedDescriptor(_obj, "insertPolicy", [_object.action], Object.getOwnPropertyDescriptor(_obj, "insertPolicy"), _obj), _applyDecoratedDescriptor(_obj, "updatePolicy", [_object.action], Object.getOwnPropertyDescriptor(_obj, "updatePolicy"), _obj)), _obj));
});