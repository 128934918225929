define("discourse/plugins/discourse-policy/discourse/components/policy-group-input", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let PolicyGroupInput = _exports.default = (_dec = (0, _object.computed)("groups.[]"), _dec2 = (0, _object.computed)("site.groups.[]"), (_class = class PolicyGroupInput extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "tagName", null);
      _defineProperty(this, "groups", null);
      _defineProperty(this, "onChangeGroup", null);
    }
    get selectedGroups() {
      return (this.groups || "").split(",").filter(Boolean);
    }
    get availableGroups() {
      return (this.site.groups || []).map(g => {
        if (g.id === 0) {
          return;
        } // prevents group "everyone" to be listed

        return g.name;
      }).filter(Boolean);
    }
    onChange(values) {
      if (this.onChangeGroup) {
        this.onChangeGroup(values.join(","));
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "selectedGroups", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selectedGroups"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "availableGroups", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "availableGroups"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype)), _class));
});