define("discourse/plugins/discourse-policy/discourse/templates/components/policy-group-input", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <GroupChooser
    @content={{availableGroups}}
    @valueProperty={{null}}
    @nameProperty={{null}}
    @value={{selectedGroups}}
    @onChange={{action "onChange"}}
  />
  */
  {
    "id": "AMHMDQAI",
    "block": "[[[8,[39,0],null,[[\"@content\",\"@valueProperty\",\"@nameProperty\",\"@value\",\"@onChange\"],[[30,0,[\"availableGroups\"]],null,null,[30,0,[\"selectedGroups\"]],[28,[37,1],[[30,0],\"onChange\"],null]]],null],[1,[28,[32,0],[\"[[\\\"The `availableGroups` property path was used in the `discourse/plugins/discourse-policy/discourse/templates/components/policy-group-input.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.availableGroups}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `selectedGroups` property path was used in the `discourse/plugins/discourse-policy/discourse/templates/components/policy-group-input.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.selectedGroups}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"group-chooser\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-policy/discourse/templates/components/policy-group-input.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});