define("discourse/plugins/discourse-policy/discourse/components/policy-reminder-input", ["exports", "@ember/component", "I18n"], function (_exports, _component, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const VALID_REMINDERS = [{
    id: "daily",
    name: _I18n.default.t("daily")
  }, {
    id: "weekly",
    name: _I18n.default.t("weekly")
  }];
  class PolicyReminderInput extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "tagName", "");
      _defineProperty(this, "reminder", null);
      _defineProperty(this, "onChangeReminder", null);
      _defineProperty(this, "validReminders", VALID_REMINDERS);
    }
  }
  _exports.default = PolicyReminderInput;
});